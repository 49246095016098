html body {
  margin: 0 auto;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  max-width: 750px;
  color: #333;
}

html,
body,
#root {
  height: 100%;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

button {
  background-color: transparent;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  padding: 0;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

@media screen and (min-width: 750px) {
  html {
    font-size: 75px !important;
  }
}

input::-webkit-input-placeholder {
  font-weight: 400 !important;
  color: #999 !important;
}
input::-moz-placeholder {
  font-weight: 400 !important;
  color: #999 !important;
}
